import { Component, OnDestroy } from '@angular/core';
import { NavController, Platform } from '@ionic/angular';
import { first } from 'rxjs/operators';
import { SplashScreen } from '@capacitor/splash-screen';
import { StatusBar, Style } from '@capacitor/status-bar';
import { OneSignal } from '@ionic-native/onesignal/ngx';
import { AuthService } from './services/auth-service.service';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Capacitor, PluginListenerHandle } from '@capacitor/core';
import { Network } from '@capacitor/network';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnDestroy{
  noInternet = false;
  networkStatus: any;
  networkListener: PluginListenerHandle;

  constructor(
    public authService: AuthService,
    private platform: Platform,
    private oneSignal: OneSignal,
    public  afAuth: AngularFireAuth,
    private nav: NavController
  ) {
    this.initializeApp();
  }
  isLoggedIn() {
    return this.afAuth.authState.pipe(first()).toPromise();
  }

  // async startupAuthState() {
    // const user = await this.isLoggedIn();
    // if (user) {
    //   this.nav.navigateRoot(['/tabs/tab1']);
    //   this.initializeApp();
    // }
  // }

  initializeApp() {
    this.platform.ready().then(() => {
      if(Capacitor.isPluginAvailable('StatusBar')) {
        StatusBar.setStyle({ style: Style.Light });
      }
      if(Capacitor.isPluginAvailable('SplashScreen')) {
        SplashScreen.hide();
      }
      if(Capacitor.isPluginAvailable('PushNotifications')) {
        this.setNotifications();
      }
      if(Capacitor.isPluginAvailable('Network')) {
        this.networkListener = Network.addListener('networkStatusChange', (status) => {
          this.networkStatus = status;
          this.noInternet = !this.networkStatus.connected;
          console.log('Network status changed', status);
        });
        this.getNetWorkStatus();
      }

    });
  }

  async getNetWorkStatus() {
    this.networkStatus = await Network.getStatus();
    console.log(this.networkStatus);
  }

  endNetworkListener() {
    if (this.networkListener) {
      this.networkListener.remove();
    }
  }

  setNotifications(){
    this.oneSignal.startInit('c9c91902-f2ca-45fc-9508-f6d2694587b6', '1004363779757');
    this.oneSignal.inFocusDisplaying(this.oneSignal.OSInFocusDisplayOption.InAppAlert);
    this.oneSignal.handleNotificationReceived().subscribe(() => {
    // do something when notification is received
    });
    this.oneSignal.handleNotificationOpened().subscribe(() => {
      // do something when a notification is opened
    });
    this.oneSignal.endInit();
  }

  ngOnDestroy() {
    if (this.networkListener) {
      this.networkListener.remove();
    }
  }

}
