import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { HttpClientModule } from '@angular/common/http';
import { OneSignal } from '@ionic-native/onesignal/ngx';

export const firebaseConfig = {
  apiKey: 'AIzaSyASEGDl431XL669V7U8O-XxJu7vdIlyHEQ',
  authDomain: 'noi-educam-voi-evoluati.firebaseapp.com',
  projectId: 'noi-educam-voi-evoluati',
  storageBucket: 'noi-educam-voi-evoluati.appspot.com',
  messagingSenderId: '1004363779757',
  appId: '1:1004363779757:web:238c921dd111fa5008c024',
  measurementId: 'G-DT6EW0559G'
};

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    AngularFireModule.initializeApp(firebaseConfig),
    AngularFireAuthModule,
    HttpClientModule
  ],
  providers: [OneSignal,{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy }],
  bootstrap: [AppComponent],
})
export class AppModule {}
