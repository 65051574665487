/* eslint-disable no-console */
import { Injectable } from '@angular/core';
import * as firebase from 'firebase/app';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Preferences } from '@capacitor/preferences';
import { AlertController, NavController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  userAuthenticated = false;
  apiUrl = 'https://apps.formprof.ro/api/noi-educam/v2/';
  oneSignal = 'https://oir.ro/apps/api/noi-educam/v2/notifications.php';
  user;
  userID;
  userItem;

  constructor(
    public  afAuth: AngularFireAuth,
    private httpClient: HttpClient,
    // tslint:disable-next-line: deprecation
    public http: HttpClient,
    public router: Router,
    private nav: NavController,
    private alertController: AlertController) {
    }

    async logout(){
      await this.afAuth.signOut();
      this.clear();
      this.router.navigate(['']);
    }


    async saveUserLocal(userObj) {
      await Preferences.set({
        key: 'FB',
        value: JSON.stringify(userObj)
      });
    }

    async clear() {
      await Preferences.clear();
    }

    postData(credentials, type) {
      return new Promise((resolve, reject) => {
        const headers = new HttpHeaders();
        this.http.post(this.apiUrl + type, JSON.stringify(credentials),{headers})
          .subscribe(res => {
            resolve(res);
          }, (err) => {
            reject(err);
          });
      });
    }

    getNotifications() {
      return new Promise((resolve, reject) => {
        const headers = new HttpHeaders();
        this.http.get(this.oneSignal,{headers})
          .subscribe(res => {
            resolve(res);
          }, (err) => {
            reject(err);
          });
      });
    }

    async notAuthenticated() {
      const alert = await this.alertController.create({
        header: 'Utilizator Neautentificat',
        message: 'Trebuie sa te autentifici pentru a avea acces la aceasta functie!',
        buttons: [
          {
            text: 'Renunta',
            role: 'cancel'
          },
          {
            text: 'Autentificare',
            role: 'confirm'
          },
        ],
      });
      await alert.present();
      const { role } = await alert.onDidDismiss();
      if(role==='confirm'){
        this.nav.navigateRoot(['tabs/tab3']);
      }
    }

    async authCheck(){
      let drt = '';
      const dr = await Preferences.get({ key: 'DR' });
      console.log(dr.value);
      if(dr.value != null){
        const raw = JSON.parse(dr.value);
        drt = raw.drt;
        return (drt.length > 10) ? true : false;
      } else {
        return false;
      }
    }


}
